import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import {
    Container,
    Typography,
    Paper,
    Box,
    TextField,
    Button,
    CircularProgress,
    Rating,
    Alert,
} from '@mui/material';
import Layout from '../components/layout';

const ReviewCreatePage = () => {
    const [name, setName] = useState('');
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError('');
        setSuccess(false);

        axios.post('/api/create-review/', {
            name,
            rating,
            comment,
        }, {
            headers: {
                'X-CSRFToken': Cookies.get('csrftoken'),
            },
        })
            .then(response => {
                if (response.status === 201) {
                    setSuccess(true);
                    setName('');
                    setRating(0);
                    setComment('');
                } else {
                    setError('Mislukt om review te verzenden, probeer het opnieuw.');
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    // Extract and show the error message from the serializer
                    setError(error.response.data.non_field_errors || 'Mislukt om review te verzenden, probeer het opnieuw.');
                } else {
                    setError('Mislukt om review te verzenden, probeer het opnieuw.');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Layout>
            <Container maxWidth="sm" sx={{ mt: 5 }}>
                <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
                    Schrijf een review
                </Typography>
                <Paper elevation={3} sx={{ p: 3, bgcolor: '#fff' }}>
                    {success && <Alert severity="success">Review succesvol verzonden</Alert>}
                    {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
                    <form onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            label="Naam"
                            variant="outlined"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            sx={{ mb: 2 }}
                        />
                        <Box sx={{ mb: 2 }}>
                            <Typography component="legend">Rating</Typography>
                            <Rating
                                name="rating"
                                value={rating}
                                onChange={(event, newValue) => setRating(newValue)}
                                precision={0.5}
                                max={5}
                            />
                        </Box>
                        <TextField
                            fullWidth
                            label="Comment"
                            variant="outlined"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            required
                            multiline
                            rows={4}
                            sx={{ mb: 2 }}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={loading}
                            sx={{ textTransform: 'none' }}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Verzenden'}
                        </Button>
                    </form>
                </Paper>
            </Container>
        </Layout>
    );
};

export default ReviewCreatePage;
