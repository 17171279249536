import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Container,
    TextField,
    Button,
    MenuItem,
    Typography,
    Box,
    InputAdornment,
    Paper,
    FormControl,
    FormHelperText,
    Dialog,
    IconButton,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PhoneIcon from '@mui/icons-material/Phone';
import Cookies from 'js-cookie';
import Layout from '../components/layout';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

const theme = createTheme({
    palette: {
        primary: {
            main: '#f8c1c1',
        },
        background: {
            default: '#f5e6da',
        },
        text: {
            primary: '#5d4037',
        },
    },
    typography: {
        h4: {
            fontWeight: 600,
        },
        body1: {
            fontSize: '1rem',
        },
    },
});

const BookingPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        service: '',
        availability: '',
        description: '',
    });

    const [services, setServices] = useState([]);
    const [availabilities, setAvailabilities] = useState([]);
    const [phoneError, setPhoneError] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const location = useLocation();
    const { state } = location;

    useEffect(() => {
        axios.get('/api/services/')
            .then(response => {
                setServices(response.data);
            })
            .catch(error => {
                console.error("There was an error fetching the services!", error);
            });

        axios.get('/api/availability/')
            .then(response => {
                setAvailabilities(response.data);
            })
            .catch(error => {
                console.error("There was an error fetching the availabilities!", error);
            });
    }, []);

    useEffect(() => {
        if (state?.service) {
            setFormData(prevState => ({
                ...prevState,
                service: state.service.id,
            }));
        }
    }, [state]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });

        if (e.target.name === 'phone') {
            const phoneValid = validatePhoneNumber(e.target.value);
            setPhoneError(!phoneValid);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (phoneError) {
            setErrorMessage('Vul een geldig telefoonnummer in.');
            setOpenErrorDialog(true);
            return;
        }

        setOpenConfirmDialog(true);
    };

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    };

    const handleConfirm = () => {
        axios.post('/api/book/', formData, {
            headers: {
                'X-CSRFToken': Cookies.get('csrftoken'),
            },
        })
            .then(response => {
                setOpenSuccessDialog(true);
                setAvailabilities(prevAvailabilities =>
                    prevAvailabilities.filter(
                        avail => avail.id !== formData.availability
                    )
                );

                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    service: '',
                    availability: '',
                    description: '',
                });
            })
            .catch(error => {
                let errorMessage = "Er is iets fout gegaan bij het maken van de afspraak. Probeer het later opnieuw of neem contact op de mail.";
                console.log(error.response.data.non_field_errors[0])
                if (error.response && error.response.data) {
                    if (error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else if (Array.isArray(error.response.data) && error.response.data.length > 0) {
                        errorMessage = error.response.data[0];  // Assuming error.response.data is an array of strings.
                    } else if (error.response.data.non_field_errors) {
                        errorMessage = error.response.data.non_field_errors[0]
                    } else if (typeof error.response.data === 'string') {
                        errorMessage = error.response.data;  // Handle case where data itself is a string.
                    }
                    setFormData({
                        name: '',
                        email: '',
                        phone: '',
                        service: '',
                        availability: '',
                        description: '',
                    });
                }

                setErrorMessage(errorMessage);
                setOpenErrorDialog(true);
            });

        handleCloseConfirmDialog();
    };

    const validatePhoneNumber = (phone) => {
        const phonePattern = /^\+?[0-9]{10,15}$/;
        return phonePattern.test(phone);
    };

    const selectedAvailability = availabilities.find(avail => avail.id === formData.availability);
    const selectedService = services.find(service => service.id === formData.service);

    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', pt: 2 }}>
                        <Paper
                            elevation={3}
                            sx={{
                                padding: 4,
                                borderRadius: '12px',
                                bgcolor: theme => theme.palette.background.default,
                                width: '100%',
                                maxWidth: '600px',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Typography variant="h4" color="text.primary" gutterBottom align="center">
                                Maak een afspraak
                            </Typography>
                            <form onSubmit={handleSubmit}>
                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        label="Naam"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                    <FormHelperText>Vul je volledige naam in.</FormHelperText>
                                </FormControl>

                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        label="Email"
                                        name="email"
                                        type="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                    <FormHelperText>Je e-mailadres gebruiken voor bevestigingen.</FormHelperText>
                                </FormControl>

                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        label="Telefoonnummer"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        required
                                        error={phoneError}
                                        helperText={phoneError ? 'Voer een geldig telefoonnummer in.' : ''}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PhoneIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <FormHelperText>Gebruik een geldig telefoonnummer.</FormHelperText>
                                </FormControl>

                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        select
                                        label="Behandeling"
                                        name="service"
                                        value={formData.service}
                                        onChange={handleChange}
                                        required
                                    >
                                        {services.map((service) => (
                                            <MenuItem key={service.id} value={service.id}>
                                                {service.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <FormHelperText>Kies de gewenste behandeling.</FormHelperText>
                                </FormControl>

                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        select
                                        label="Mijn beschikbaarheid"
                                        name="availability"
                                        value={formData.availability}
                                        onChange={handleChange}
                                        required
                                    >
                                        {availabilities.map((availability) => (
                                            <MenuItem key={availability.id} value={availability.id}>
                                                {format(availability.date, 'dd-MM-yyyy')} {availability.time}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <FormHelperText>Kies een beschikbaarheid voor je afspraak.</FormHelperText>
                                </FormControl>

                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        label="Omschrijving"
                                        name="description"
                                        value={formData.description}
                                        onChange={handleChange}
                                        multiline
                                        required
                                    />
                                    <FormHelperText>Geef een korte omschrijving van wat u wilt laten doen. LET OP: prijzen kunnen afwijken afhankelijk van wat u wilt laten doen.</FormHelperText>
                                </FormControl>

                                <Box mt={2}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        sx={{
                                            fontSize: '1rem',
                                            padding: '10px',
                                            textTransform: 'none',
                                        }}
                                    >
                                        Maak afspraak
                                    </Button>
                                </Box>
                            </form>
                        </Paper>
                    </Container>
                </LocalizationProvider>

                {/* Confirmation Dialog */}
                <Dialog
                    open={openConfirmDialog}
                    onClose={handleCloseConfirmDialog}
                    maxWidth="sm"
                    fullWidth
                    PaperProps={{
                        sx: {
                            borderRadius: '16px',
                            padding: '16px',
                            boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
                            bgcolor: theme => theme.palette.background.default,
                        },
                    }}
                >
                    <Box sx={{ position: 'relative' }}>
                        <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                            Bevestiging
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleCloseConfirmDialog}
                            aria-label="close"
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="body1" paragraph>
                            Je boekt een afspraak voor de behandeling: {selectedService?.name || 'Onbekend'}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Beschikbaarheid: {selectedAvailability ? format(parseISO(selectedAvailability.date), 'dd-MM-yyyy') + ' ' + selectedAvailability.time : 'Onbekend'}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Naam: {formData.name}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Email: {formData.email}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Telefoonnummer: {formData.phone}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Omschrijving: {formData.description}
                        </Typography>
                        <Box mt={2} display="flex" justifyContent="center">
                            <Button variant="contained" sx={{ margin: 'auto', textTransform: 'none', }} onClick={handleCloseConfirmDialog} color="primary">Annuleren</Button>
                            <Button variant="contained" sx={{ margin: 'auto', textTransform: 'none', }} onClick={handleConfirm} color="primary">Bevestigen</Button>
                        </Box>
                    </Box>
                </Dialog>

                {/* Success Dialog */}
                <Dialog
                    open={openSuccessDialog}
                    onClose={() => setOpenSuccessDialog(false)}
                    maxWidth="sm"
                    fullWidth
                    PaperProps={{
                        sx: {
                            borderRadius: '16px',
                            padding: '16px',
                            boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
                            bgcolor: '#dff0d8',
                        },
                    }}
                >
                    <Box sx={{ position: 'relative' }}>
                        <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                            Afspraak Bevestigd!
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => setOpenSuccessDialog(false)}
                            aria-label="close"
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="body1" paragraph>
                            Je afspraak is succesvol geboekt.
                        </Typography>
                        <Box mt={2} display="flex" justifyContent="center">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setOpenSuccessDialog(false)}
                                sx={{ textTransform: 'none' }}
                            >
                                Oké
                            </Button>
                        </Box>
                    </Box>
                </Dialog>

                {/* Error Dialog */}
                <Dialog
                    open={openErrorDialog}
                    onClose={() => setOpenErrorDialog(false)}
                    maxWidth="sm"
                    fullWidth
                    PaperProps={{
                        sx: {
                            borderRadius: '16px',
                            padding: '16px',
                            boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
                            bgcolor: '#f8d7da',
                        },
                    }}
                >
                    <Box sx={{ position: 'relative' }}>
                        <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                            Oeps, iets ging fout!
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => setOpenErrorDialog(false)}
                            aria-label="close"
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="body1" paragraph>
                            {errorMessage}
                        </Typography>
                        <Box mt={2} display="flex" justifyContent="center">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setOpenErrorDialog(false)}
                                sx={{ textTransform: 'none' }}
                            >
                                Sluiten
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
            </Layout>
        </ThemeProvider>
    );
};

export default BookingPage;
