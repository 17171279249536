import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Container } from "@mui/material";
import Footer from "./footer";
import Navbar from "./navbar";


const theme = createTheme({
    palette: {
        primary: {
            main: '#f8c1c1', // Pink-nude color for the toolbar
        },
        background: {
            default: '#f5e6da', // Nude color for the background
        },
        text: {
            primary: '#5d4037', // Dark brown color for the text
        },
    },
    typography: {
        h2: {
            fontWeight: 700,
        },
        body1: {
            fontSize: '1.2rem',
        },
    },
});

const Layout = ({ children }) => {

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}>
                <Navbar />
                <Container>
                    {children}
                </Container>
                <Footer />
            </Box>
        </ThemeProvider>
    );
};

export default Layout;
