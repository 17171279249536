import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
};

function Carousel() {
    const [homepageData, setHomepageData] = useState({
        title: '',
        description: '',
        images: []
    });

    const [imageDimensions, setImageDimensions] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch the data from the API
        fetch('/api/homepage/')
            .then((response) => response.json())
            .then((data) => {
                // Extract the data
                const fetchedImages = [data.image1, data.image2, data.image3, data.image4].filter(Boolean);
                setHomepageData({
                    title: data.title,
                    description: data.description,
                    images: fetchedImages
                });

                // Load image dimensions
                const newDimensions = {};
                fetchedImages.forEach((image, index) => {
                    const img = new Image();
                    img.src = image;
                    img.onload = () => {
                        newDimensions[index] = {
                            width: img.width,
                            height: img.height,
                        };
                        setImageDimensions((prev) => ({ ...prev, ...newDimensions }));
                    };
                });
            })
            .catch((error) => console.error('Error fetching homepage data:', error));
    }, []);

    return (
        <Box>
            {/* Hero Section */}
            <Box textAlign="center" mt={4} mb={6}>
                <Typography
                    variant="h2"
                    gutterBottom
                    sx={{
                        fontWeight: 300,
                        fontSize: { xs: '2rem', sm: '3rem' },
                        lineHeight: '1.2',
                        color: 'text.primary',
                    }}
                >
                    {homepageData.title}
                </Typography>
                <Typography
                    variant="body1"
                    paragraph
                    sx={{
                        fontSize: { xs: '1rem', sm: '1.2rem' },
                        color: 'text.primary',
                        fontWeight: '300',
                    }}
                >
                    {homepageData.description}
                </Typography>
            </Box>
            {/* Carousel */}
            <Box sx={{ position: 'relative' }}>
                <Slider {...settings}>
                    {homepageData.images.map((image, index) => {
                        const dimensions = imageDimensions[index] || { width: 0, height: 0 };
                        const aspectRatio = dimensions.width / dimensions.height;
                        const maxHeight = 600;
                        const calculatedWidth = maxHeight * aspectRatio;

                        return (
                            <Box key={index} sx={{ maxHeight: `${maxHeight}px`, overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img
                                    src={image}
                                    alt={`Carousel ${index}`}
                                    style={{
                                        width: `${calculatedWidth}px`,
                                        height: `${maxHeight}px`,
                                        maxWidth: '100%',
                                        objectFit: 'contain',
                                        borderRadius: '8px',
                                        margin: 'auto',
                                    }}
                                />
                            </Box>
                        );
                    })}
                </Slider>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/booking')}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1,
                        padding: '10px 20px',
                        fontSize: '1rem',
                        borderRadius: '20px',
                        fontWeight: 'normal',
                        letterSpacing: '0.5px',
                        textTransform: 'none',
                        backgroundColor: 'rgba(248, 193, 193, 0.8)',
                        boxShadow: 'none',
                        '&:hover': {
                            backgroundColor: 'rgba(248, 193, 193, 1)',
                        },
                    }}
                >
                    Maak een afspraak
                </Button>
            </Box>
        </Box>
    );
}

export default Carousel;
