import React, { useState, useEffect } from "react";
import { Typography, Box, Grid, Dialog, IconButton, Divider, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Layout from "../components/layout";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function ServicePage() {
    const [services, setServices] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedService, setSelectedService] = useState(null);

    useEffect(() => {
        axios.get('/api/services/')
            .then(response => {
                setServices(response.data);
            })
            .catch(error => {
                console.error("There was an error fetching the services!", error);
            });
    }, []);

    const handleOpenDialog = (service) => {
        setSelectedService(service);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedService(null);
    };

    const navigate = useNavigate();

    const handleBookNow = () => {
        navigate('/booking', { state: { service: selectedService } });
        handleCloseDialog();
    };

    return (
        <Layout>
            <Box position="relative" my={4}>
                <Typography variant="h4" gutterBottom color="text.primary" ml={2}>
                    Behandelingen
                </Typography>
                <Typography variant="body1" color="text.primary" paragraph>
                    Hier vind je een overzicht van de behandelingen die ik aanbied.
                    Klik op een behandeling voor meer informatie en prijzen.
                </Typography>
                <Grid container spacing={8} justifyContent="center">
                    {services.map((service) => (
                        <Grid item xs={12} sm={6} md={3} key={service.id}>
                            <Box
                                sx={{
                                    p: 3,
                                    borderRadius: '12px',
                                    bgcolor: '#f5e6da',
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                    '&:hover': {
                                        transform: 'scale(1.05)',
                                        boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
                                        cursor: 'pointer',
                                    },
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                }}
                                onClick={() => handleOpenDialog(service)}
                            >
                                <Typography variant="h6" gutterBottom>
                                    {service.name}
                                </Typography>
                                <Typography variant="body2">
                                    Duur: {service.duration} minuten | Prijs: €{service.price}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>

                {/* Card-like Dialog for Service Details */}
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    maxWidth="sm"
                    fullWidth
                    PaperProps={{
                        sx: {
                            borderRadius: '16px',
                            padding: '16px',
                            boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
                            bgcolor: '#f5e6da',
                        },
                    }}
                >
                    <Box sx={{ position: 'relative' }}>
                        <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
                            {selectedService?.name}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleCloseDialog}
                            aria-label="close"
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="body1" paragraph>
                            {selectedService?.description}
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="body2">
                            Duur: {selectedService?.duration} minuten | Prijs: €{selectedService?.price}
                        </Typography>
                        <Box mt={2} display="flex" justifyContent="center">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleBookNow}
                                sx={{ margin: '0 auto', textTransform: 'none', }}
                            >
                                Boek nu
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
            </Box>
        </Layout>
    );
}

export default ServicePage;
