import React from "react";
import { Container } from "@mui/material";
import Layout from "../components/layout";
import ServicesSection from "../components/serviceSection";
import Carousel from "../components/carousel";

function HomePage() {
    return (
        <Layout>
            <Container sx={{ pt: 0, pb: 8 }}>
                <Carousel />
                <ServicesSection />
            </Container>
        </Layout>
    );
}

export default HomePage;
