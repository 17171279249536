import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/homePage";
import ServicesPage from "./pages/servicePage";
import BookingPage from "./pages/bookingPage";
import ReviewsPage from "./pages/reviewPage";
import ReviewCreatePage from "./pages/reviewCreatePage";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" Component={HomePage} />
        <Route path="/services" Component={ServicesPage} />
        <Route path="/booking" Component={BookingPage} />
        <Route path="/reviews" Component={ReviewsPage} />
        <Route path="/create-review" Component={ReviewCreatePage} />
      </Routes>
    </div>
  );
}

export default App;
