import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Container,
    Typography,
    Paper,
    Box,
    CircularProgress,
    Divider,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import Layout from '../components/layout';
import format from 'date-fns/format';

const ReviewPage = () => {
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        // Fetch reviews from API
        axios.get('/api/reviews/')
            .then(response => {
                setReviews(response.data);
                setLoading(false);
            })
            .catch(error => {
                setError('Failed to load reviews.');
                setLoading(false);
            });
    }, []);

    const renderRating = (rating) => {
        const fullStars = Math.floor(rating);
        const halfStar = rating % 1 !== 0;
        const starIcons = [];

        for (let i = 0; i < fullStars; i++) {
            starIcons.push(<StarIcon key={`full-${i}`} color="primary" />);
        }

        if (halfStar) {
            starIcons.push(<StarIcon key="half" color="primary" style={{ opacity: 0.5 }} />);
        }

        return <Box>{starIcons}</Box>;
    };

    const formatRating = (rating) => {
        // Ensure rating is a number and format it
        const numRating = parseFloat(rating);
        if (isNaN(numRating)) return "N/A";
        return numRating.toFixed(1); // Format rating to one decimal place
    };

    if (loading) {
        return (
            <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 5 }}>
                <CircularProgress />
                <Typography variant="body1" sx={{ mt: 2 }}>
                    Loading reviews...
                </Typography>
            </Container>
        );
    }

    if (error) {
        return (
            <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 5 }}>
                <Typography variant="body1" color="error">
                    {error}
                </Typography>
            </Container>
        );
    }

    return (
        <Layout>
            <Container maxWidth="md" sx={{ mt: 5 }}>
                <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
                    Customer Reviews
                </Typography>
                {reviews.length > 0 ? (
                    reviews.map((review) => (
                        <Paper key={review.date} elevation={3} sx={{ mb: 2, p: 3, bgcolor: '#fff' }}>
                            <Typography variant="h6">{review.name}</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                {renderRating(parseFloat(review.rating))}
                                <Typography variant="body2" sx={{ ml: 1 }}>
                                    {formatRating(review.rating)}
                                </Typography>
                            </Box>
                            <Typography variant="body1">{review.comment}</Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                                {format(review.date, 'dd-MM-yyyy')}
                            </Typography>
                            <Divider sx={{ mt: 2 }} />
                        </Paper>
                    ))
                ) : (
                    <Typography>No reviews available.</Typography>
                )}
            </Container>
        </Layout>
    );
};

export default ReviewPage;
